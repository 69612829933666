import { UrlId, UrlResponse, UrlsApi } from "api";
import { useQuery } from "@tanstack/react-query";

type Args = {
  urlId: UrlId;
};

export function useUrlQuery({ urlId }: Args) {
  const fetch = async () => {
    const api = new UrlsApi();
    const result = await api.getUrlApiV1CrawlerUrlsUrlIdGet(urlId);
    return result.data;
  };
  return useQuery<UrlResponse>(["crawler", "url", urlId], () => fetch(), {
    enabled: urlId !== "",
  });
}
