import { NewsApi } from "api";
import { useMutation } from "@tanstack/react-query";

type Params = {
  pageId: number;
};
const useExtractNewsMutation = () => {
  const fetch = async ({ pageId }: Params) => {
    const api = new NewsApi();
    const result = await api.extractNewsApiV1NewsArticlesV2ExtractPageIdPost(
      pageId,
    );
    return result.data;
  };

  return useMutation({
    mutationFn: ({ pageId }: Params) => fetch({ pageId }),
  });
};

export default useExtractNewsMutation;
