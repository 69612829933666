import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

import React from "react";

const ButtonMailto = ({ mailto, label }: { mailto: string; label: string }) => {
  return (
    <Button
      bg={"blue.400"}
      rounded={"full"}
      color={"white"}
      _hover={{ bg: "blue.500" }}
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Button>
  );
};

export default function HomePage() {
  return (
    <Flex
      w={"full"}
      h={"100vh"}
      backgroundImage={
        "url(https://images.unsplash.com/photo-1676906871038-44927a3b8089?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1035&q=80)"
      }
      backgroundSize={"cover"}
      backgroundPosition={"center center"}
    >
      <VStack
        w={"full"}
        justify={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
      >
        <Stack maxW={"2xl"} align={"flex-start"} spacing={6}>
          <Text
            color={"white"}
            fontWeight={700}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: "3xl", md: "4xl" })}
          >
            Welcome to Poseidon Research
          </Text>
          <Stack direction={"row"}>
            <ButtonMailto
              mailto="mailto:info@poseidonresearch.co"
              label="Get in touch"
            />
          </Stack>
        </Stack>
      </VStack>
    </Flex>
  );
}
