import { ChakraProvider, ColorModeScript, theme } from "@chakra-ui/react";
import { Router } from "./router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter } from "react-router-dom";
import * as React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

export const App: React.FC = () => {
  return (
    <>
      <ColorModeScript />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <Router />
            <ReactQueryDevtools initialIsOpen={false} />
          </ChakraProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};
