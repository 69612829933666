import { UrlsApi } from "api";
import { useMutation } from "@tanstack/react-query";

type Args = {
  pageId: number;
};

export const useDownloadPageMutation = () => {
  const fetch = async ({ pageId }: Args) => {
    const api = new UrlsApi();
    const result = await api.downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
      pageId,
      { responseType: "blob" },
    );
    return result;
  };
  return useMutation(
    ["crawler", "downloadPage"],
    (args: Args) => fetch(args),
    {},
  );
};
