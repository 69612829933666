import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useUrlMutation } from "../../data";
import { UrlId } from "api";
import { useNavigate } from "react-router-dom";

export const FindByUrl = () => {
  const navigate = useNavigate();
  const urlMutation = useUrlMutation();

  const schema = Yup.object().shape({
    url: Yup.string().required("Required"),
  });

  const initialValues = {
    url: "",
  };

  const onFindUrl = async (url: string) => {
    const encodedUrl = encodeURIComponent(url);
    const result = await urlMutation.mutateAsync({
      urlId: encodedUrl as UrlId,
    });
    navigate(`/staff/urls/${result.url_data.id}`);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values) => onFindUrl(values.url)}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <form onSubmit={handleSubmit}>
          <HStack>
            <FormControl>
              <HStack>
                <FormLabel htmlFor="url">Url</FormLabel>
                <Field as={Input} name="url" id="url" />
              </HStack>
            </FormControl>
            <Button type="submit" colorScheme="blue">
              Find Url
            </Button>
          </HStack>
        </form>
      )}
    </Formik>
  );
};
