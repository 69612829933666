/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from "./base";

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 *
 * @export
 * @interface News
 */
export interface News {
  /**
   *
   * @type {string}
   * @memberof News
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  hostname: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  article: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  meta_description?: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  meta_language?: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  meta_keywords?: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  meta_encoding?: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  canonical_url?: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  publish_timestamp?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof News
   */
  authors: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof News
   */
  tickers?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof News
   */
  timestamp: string;
  /**
   *
   * @type {boolean}
   * @memberof News
   */
  is_paywall?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof News
   */
  bot_detected?: boolean;
  /**
   *
   * @type {string}
   * @memberof News
   */
  version?: string;
  /**
   *
   * @type {number}
   * @memberof News
   */
  id?: number;
}
/**
 *
 * @export
 * @interface NewsResponse
 */
export interface NewsResponse {
  /**
   *
   * @type {Array<News>}
   * @memberof NewsResponse
   */
  articles: Array<News>;
  /**
   *
   * @type {number}
   * @memberof NewsResponse
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof NewsResponse
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof NewsResponse
   */
  total?: number;
}
/**
 *
 * @export
 * @interface NewsTicker
 */
export interface NewsTicker {
  /**
   *
   * @type {string}
   * @memberof NewsTicker
   */
  symbol: string;
  /**
   *
   * @type {number}
   * @memberof NewsTicker
   */
  ticker_id: number;
  /**
   *
   * @type {string}
   * @memberof NewsTicker
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof NewsTicker
   */
  starting_index: number;
  /**
   *
   * @type {number}
   * @memberof NewsTicker
   */
  ending_index: number;
}
/**
 *
 * @export
 * @interface NewsV2
 */
export interface NewsV2 {
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  canonical_url?: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  hostname: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  article: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  meta_description?: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  meta_keywords?: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  publish_timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  modified_timestamp?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof NewsV2
   */
  authors: Array<string>;
  /**
   *
   * @type {Array<NewsTicker>}
   * @memberof NewsV2
   */
  html_tickers: Array<NewsTicker>;
  /**
   *
   * @type {Array<NewsTicker>}
   * @memberof NewsV2
   */
  title_tickers: Array<NewsTicker>;
  /**
   *
   * @type {Array<NewsTicker>}
   * @memberof NewsV2
   */
  sitemap_tickers: Array<NewsTicker>;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  timestamp: string;
  /**
   *
   * @type {boolean}
   * @memberof NewsV2
   */
  is_paywall?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NewsV2
   */
  bot_detected?: boolean;
  /**
   *
   * @type {string}
   * @memberof NewsV2
   */
  version?: string;
  /**
   *
   * @type {number}
   * @memberof NewsV2
   */
  id?: number;
}
/**
 *
 * @export
 * @interface PageMetadata
 */
export interface PageMetadata {
  /**
   *
   * @type {number}
   * @memberof PageMetadata
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof PageMetadata
   */
  url_id: number;
  /**
   *
   * @type {string}
   * @memberof PageMetadata
   */
  timestamp: string;
}
/**
 *
 * @export
 * @interface PagesResponse
 */
export interface PagesResponse {
  /**
   *
   * @type {Array<PageMetadata>}
   * @memberof PagesResponse
   */
  pages: Array<PageMetadata>;
  /**
   *
   * @type {number}
   * @memberof PagesResponse
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof PagesResponse
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PagesResponse
   */
  total: number;
}
/**
 *
 * @export
 * @interface UrlData
 */
export interface UrlData {
  /**
   *
   * @type {string}
   * @memberof UrlData
   */
  url: string;
  /**
   *
   * @type {number}
   * @memberof UrlData
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UrlData
   */
  last_crawled?: string;
  /**
   *
   * @type {number}
   * @memberof UrlData
   */
  last_status_code?: number;
}
/**
 *
 * @export
 * @interface UrlId
 */
export interface UrlId {}
/**
 *
 * @export
 * @interface UrlResponse
 */
export interface UrlResponse {
  /**
   *
   * @type {UrlData}
   * @memberof UrlResponse
   */
  url_data: UrlData;
}
/**
 *
 * @export
 * @interface UrlsResponse
 */
export interface UrlsResponse {
  /**
   *
   * @type {Array<UrlData>}
   * @memberof UrlsResponse
   */
  urls: Array<UrlData>;
  /**
   *
   * @type {number}
   * @memberof UrlsResponse
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof UrlsResponse
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof UrlsResponse
   */
  total: number;
}
/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}

/**
 * CrawlerApi - axios parameter creator
 * @export
 */
export const CrawlerApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet: async (
      pageId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists(
        "downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet",
        "pageId",
        pageId,
      );
      const localVarPath =
        `/api/v1/crawler/urls/pages/{page_id}/download/`.replace(
          `{${"page_id"}}`,
          encodeURIComponent(String(pageId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet: async (
      urlId: number,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'urlId' is not null or undefined
      assertParamExists(
        "getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet",
        "urlId",
        urlId,
      );
      const localVarPath = `/api/v1/crawler/urls/{url_id}/pages/`.replace(
        `{${"url_id"}}`,
        encodeURIComponent(String(urlId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlApiV1CrawlerUrlsUrlIdGet: async (
      urlId: UrlId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'urlId' is not null or undefined
      assertParamExists("getUrlApiV1CrawlerUrlsUrlIdGet", "urlId", urlId);
      const localVarPath = `/api/v1/crawler/urls/{url_id}/`.replace(
        `{${"url_id"}}`,
        encodeURIComponent(String(urlId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlsApiV1CrawlerUrlsGet: async (
      offset?: number,
      limit?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/crawler/urls/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CrawlerApi - functional programming interface
 * @export
 */
export const CrawlerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CrawlerApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
      pageId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
          pageId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
      urlId: number,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
          urlId,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUrlApiV1CrawlerUrlsUrlIdGet(
      urlId: UrlId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUrlApiV1CrawlerUrlsUrlIdGet(
          urlId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUrlsApiV1CrawlerUrlsGet(
      offset?: number,
      limit?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUrlsApiV1CrawlerUrlsGet(
          offset,
          limit,
          query,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * CrawlerApi - factory interface
 * @export
 */
export const CrawlerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CrawlerApiFp(configuration);
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
      pageId: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(pageId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
      urlId: number,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<PagesResponse> {
      return localVarFp
        .getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
          urlId,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlApiV1CrawlerUrlsUrlIdGet(
      urlId: UrlId,
      options?: any,
    ): AxiosPromise<UrlResponse> {
      return localVarFp
        .getUrlApiV1CrawlerUrlsUrlIdGet(urlId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlsApiV1CrawlerUrlsGet(
      offset?: number,
      limit?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<UrlsResponse> {
      return localVarFp
        .getUrlsApiV1CrawlerUrlsGet(offset, limit, query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CrawlerApi - object-oriented interface
 * @export
 * @class CrawlerApi
 * @extends {BaseAPI}
 */
export class CrawlerApi extends BaseAPI {
  /**
   *
   * @summary Download Page
   * @param {number} pageId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrawlerApi
   */
  public downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
    pageId: number,
    options?: AxiosRequestConfig,
  ) {
    return CrawlerApiFp(this.configuration)
      .downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(pageId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Pages For Url
   * @param {number} urlId
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrawlerApi
   */
  public getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
    urlId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return CrawlerApiFp(this.configuration)
      .getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
        urlId,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Url
   * @param {UrlId} urlId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrawlerApi
   */
  public getUrlApiV1CrawlerUrlsUrlIdGet(
    urlId: UrlId,
    options?: AxiosRequestConfig,
  ) {
    return CrawlerApiFp(this.configuration)
      .getUrlApiV1CrawlerUrlsUrlIdGet(urlId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Urls
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CrawlerApi
   */
  public getUrlsApiV1CrawlerUrlsGet(
    offset?: number,
    limit?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return CrawlerApiFp(this.configuration)
      .getUrlsApiV1CrawlerUrlsGet(offset, limit, query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointHealthGet: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endpointHealthGet(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.endpointHealthGet(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endpointHealthGet(options?: any): AxiosPromise<any> {
      return localVarFp
        .endpointHealthGet(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Endpoint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public endpointHealthGet(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .endpointHealthGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extractNewsApiV1NewsArticlesV2ExtractPageIdPost: async (
      pageId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists(
        "extractNewsApiV1NewsArticlesV2ExtractPageIdPost",
        "pageId",
        pageId,
      );
      const localVarPath =
        `/api/v1/news/articles_v2/extract/{page_id}/`.replace(
          `{${"page_id"}}`,
          encodeURIComponent(String(pageId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1: async (
      pageId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists(
        "extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1",
        "pageId",
        pageId,
      );
      const localVarPath =
        `/api/v1/news/articles_v2/extract/{page_id}/`.replace(
          `{${"page_id"}}`,
          encodeURIComponent(String(pageId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsApiV1NewsArticlesUrlGet: async (
      url: string,
      offset: number,
      limit: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'url' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet", "url", url);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet", "offset", offset);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet", "limit", limit);
      const localVarPath = `/api/v1/news/articles/{url}/`.replace(
        `{${"url"}}`,
        encodeURIComponent(String(url)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsApiV1NewsArticlesUrlGet_2: async (
      url: string,
      offset: number,
      limit: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'url' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet_2", "url", url);
      // verify required parameter 'offset' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet_2", "offset", offset);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists("getNewsApiV1NewsArticlesUrlGet_2", "limit", limit);
      const localVarPath = `/api/v1/news/articles/{url}/`.replace(
        `{${"url"}}`,
        encodeURIComponent(String(url)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extractNewsApiV1NewsArticlesV2ExtractPageIdPost(
      pageId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsV2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.extractNewsApiV1NewsArticlesV2ExtractPageIdPost(
          pageId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(
      pageId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsV2>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(
          pageId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewsApiV1NewsArticlesUrlGet(
      url: string,
      offset: number,
      limit: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNewsApiV1NewsArticlesUrlGet(
          url,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNewsApiV1NewsArticlesUrlGet_2(
      url: string,
      offset: number,
      limit: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getNewsApiV1NewsArticlesUrlGet_2(
          url,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NewsApiFp(configuration);
  return {
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extractNewsApiV1NewsArticlesV2ExtractPageIdPost(
      pageId: number,
      options?: any,
    ): AxiosPromise<NewsV2> {
      return localVarFp
        .extractNewsApiV1NewsArticlesV2ExtractPageIdPost(pageId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Extract News
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(
      pageId: number,
      options?: any,
    ): AxiosPromise<NewsV2> {
      return localVarFp
        .extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(pageId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsApiV1NewsArticlesUrlGet(
      url: string,
      offset: number,
      limit: number,
      options?: any,
    ): AxiosPromise<NewsResponse> {
      return localVarFp
        .getNewsApiV1NewsArticlesUrlGet(url, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get News
     * @param {string} url
     * @param {number} offset
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNewsApiV1NewsArticlesUrlGet_2(
      url: string,
      offset: number,
      limit: number,
      options?: any,
    ): AxiosPromise<NewsResponse> {
      return localVarFp
        .getNewsApiV1NewsArticlesUrlGet_2(url, offset, limit, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
  /**
   *
   * @summary Extract News
   * @param {number} pageId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public extractNewsApiV1NewsArticlesV2ExtractPageIdPost(
    pageId: number,
    options?: AxiosRequestConfig,
  ) {
    return NewsApiFp(this.configuration)
      .extractNewsApiV1NewsArticlesV2ExtractPageIdPost(pageId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Extract News
   * @param {number} pageId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(
    pageId: number,
    options?: AxiosRequestConfig,
  ) {
    return NewsApiFp(this.configuration)
      .extractNewsApiV1NewsArticlesV2ExtractPageIdPost_1(pageId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get News
   * @param {string} url
   * @param {number} offset
   * @param {number} limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public getNewsApiV1NewsArticlesUrlGet(
    url: string,
    offset: number,
    limit: number,
    options?: AxiosRequestConfig,
  ) {
    return NewsApiFp(this.configuration)
      .getNewsApiV1NewsArticlesUrlGet(url, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get News
   * @param {string} url
   * @param {number} offset
   * @param {number} limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public getNewsApiV1NewsArticlesUrlGet_2(
    url: string,
    offset: number,
    limit: number,
    options?: AxiosRequestConfig,
  ) {
    return NewsApiFp(this.configuration)
      .getNewsApiV1NewsArticlesUrlGet_2(url, offset, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UrlsApi - axios parameter creator
 * @export
 */
export const UrlsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet: async (
      pageId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists(
        "downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet",
        "pageId",
        pageId,
      );
      const localVarPath =
        `/api/v1/crawler/urls/pages/{page_id}/download/`.replace(
          `{${"page_id"}}`,
          encodeURIComponent(String(pageId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet: async (
      urlId: number,
      offset?: number,
      limit?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'urlId' is not null or undefined
      assertParamExists(
        "getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet",
        "urlId",
        urlId,
      );
      const localVarPath = `/api/v1/crawler/urls/{url_id}/pages/`.replace(
        `{${"url_id"}}`,
        encodeURIComponent(String(urlId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlApiV1CrawlerUrlsUrlIdGet: async (
      urlId: UrlId,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'urlId' is not null or undefined
      assertParamExists("getUrlApiV1CrawlerUrlsUrlIdGet", "urlId", urlId);
      const localVarPath = `/api/v1/crawler/urls/{url_id}/`.replace(
        `{${"url_id"}}`,
        encodeURIComponent(String(urlId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlsApiV1CrawlerUrlsGet: async (
      offset?: number,
      limit?: number,
      query?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/crawler/urls/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (offset !== undefined) {
        localVarQueryParameter["offset"] = offset;
      }

      if (limit !== undefined) {
        localVarQueryParameter["limit"] = limit;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UrlsApi - functional programming interface
 * @export
 */
export const UrlsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UrlsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
      pageId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
          pageId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
      urlId: number,
      offset?: number,
      limit?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PagesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
          urlId,
          offset,
          limit,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUrlApiV1CrawlerUrlsUrlIdGet(
      urlId: UrlId,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUrlApiV1CrawlerUrlsUrlIdGet(
          urlId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUrlsApiV1CrawlerUrlsGet(
      offset?: number,
      limit?: number,
      query?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UrlsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUrlsApiV1CrawlerUrlsGet(
          offset,
          limit,
          query,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UrlsApi - factory interface
 * @export
 */
export const UrlsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UrlsApiFp(configuration);
  return {
    /**
     *
     * @summary Download Page
     * @param {number} pageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
      pageId: number,
      options?: any,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(pageId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Pages For Url
     * @param {number} urlId
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
      urlId: number,
      offset?: number,
      limit?: number,
      options?: any,
    ): AxiosPromise<PagesResponse> {
      return localVarFp
        .getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
          urlId,
          offset,
          limit,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Url
     * @param {UrlId} urlId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlApiV1CrawlerUrlsUrlIdGet(
      urlId: UrlId,
      options?: any,
    ): AxiosPromise<UrlResponse> {
      return localVarFp
        .getUrlApiV1CrawlerUrlsUrlIdGet(urlId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Urls
     * @param {number} [offset]
     * @param {number} [limit]
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUrlsApiV1CrawlerUrlsGet(
      offset?: number,
      limit?: number,
      query?: string,
      options?: any,
    ): AxiosPromise<UrlsResponse> {
      return localVarFp
        .getUrlsApiV1CrawlerUrlsGet(offset, limit, query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UrlsApi - object-oriented interface
 * @export
 * @class UrlsApi
 * @extends {BaseAPI}
 */
export class UrlsApi extends BaseAPI {
  /**
   *
   * @summary Download Page
   * @param {number} pageId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UrlsApi
   */
  public downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(
    pageId: number,
    options?: AxiosRequestConfig,
  ) {
    return UrlsApiFp(this.configuration)
      .downloadPageApiV1CrawlerUrlsPagesPageIdDownloadGet(pageId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Pages For Url
   * @param {number} urlId
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UrlsApi
   */
  public getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
    urlId: number,
    offset?: number,
    limit?: number,
    options?: AxiosRequestConfig,
  ) {
    return UrlsApiFp(this.configuration)
      .getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
        urlId,
        offset,
        limit,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Url
   * @param {UrlId} urlId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UrlsApi
   */
  public getUrlApiV1CrawlerUrlsUrlIdGet(
    urlId: UrlId,
    options?: AxiosRequestConfig,
  ) {
    return UrlsApiFp(this.configuration)
      .getUrlApiV1CrawlerUrlsUrlIdGet(urlId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Urls
   * @param {number} [offset]
   * @param {number} [limit]
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UrlsApi
   */
  public getUrlsApiV1CrawlerUrlsGet(
    offset?: number,
    limit?: number,
    query?: string,
    options?: AxiosRequestConfig,
  ) {
    return UrlsApiFp(this.configuration)
      .getUrlsApiV1CrawlerUrlsGet(offset, limit, query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
