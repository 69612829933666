import { Route, Routes } from "react-router-dom";
import { HomePage, StaffHomePage, UrlPage, UrlsPage } from "pages";
import React from "react";

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="staff" element={<StaffHomePage />}>
        <Route path="urls" element={<UrlsPage />}>
          <Route path=":urlId" element={<UrlPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
