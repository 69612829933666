import { NewsApi, NewsResponse } from "api";
import { useQuery } from "@tanstack/react-query";

type Args = {
  offset: number;
  limit: number;
  url?: string;
};

export function useNewsQuery(args: Args) {
  const fetch = async () => {
    const { offset, url, limit } = args;
    if (url === undefined) {
      throw new Error("url is undefined");
    }
    const api = new NewsApi();
    const result = await api.getNewsApiV1NewsArticlesUrlGet(
      encodeURIComponent(url),
      offset,
      limit,
    );
    return result.data;
  };
  return useQuery<NewsResponse>(
    ["crawler", "news", args.url, args.offset],
    () => fetch(),
    { enabled: args.url !== undefined },
  );
}
