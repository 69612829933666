import { UrlId, UrlsApi } from "api";
import { useMutation } from "@tanstack/react-query";

type Args = {
  urlId: UrlId;
};

export function useUrlMutation() {
  const fetch = async ({ urlId }: Args) => {
    const api = new UrlsApi();
    const result = await api.getUrlApiV1CrawlerUrlsUrlIdGet(urlId);
    return result.data;
  };
  return useMutation(["crawler", "url"], (args: Args) => fetch(args), {});
}
