import { HStack } from "@chakra-ui/react";
import { FindByUrl } from "./FindByUrl";
import { Outlet } from "react-router-dom";

export default function UrlsPage() {
  return (
    <div>
      <h1>Welcome to UrlsPage!</h1>
      <HStack>
        <FindByUrl />
      </HStack>
      <Outlet />
    </div>
  );
}
