import { UrlsApi, PagesResponse } from "api";
import { useQuery } from "@tanstack/react-query";

type Args = {
  offset: number;
  limit: number;
  urlId: number;
};

export function usePagesQuery(args: Args) {
  const fetch = async () => {
    const { offset, urlId, limit } = args;
    const api = new UrlsApi();
    const result = await api.getPagesForUrlApiV1CrawlerUrlsUrlIdPagesGet(
      urlId,
      offset,
      limit,
    );
    return result.data;
  };
  return useQuery<PagesResponse>(
    ["crawler", "pages", args.urlId, args.offset],
    () => fetch(),
  );
}
