import { useParams } from "react-router-dom";
import {
  useDownloadPageMutation,
  useNewsQuery,
  usePagesQuery,
  useUrlQuery,
} from "data";
import { UrlId } from "api";
import {
  HStack,
  VStack,
  Text,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Table,
  Spinner,
  Button,
  Thead,
} from "@chakra-ui/react";
import useExtractNewsMutation from "data/news/useExtractNewsMutation";

const saveFile = (data: ArrayBuffer, filename: string): void => {
  const blob = new Blob([data], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  setTimeout(() => {
    URL.revokeObjectURL(url);
    link.remove();
  }, 0);
};

type DownloadButtonProps = {
  pageId: number;
};

const DownloadButton = ({ pageId }: DownloadButtonProps) => {
  const downloadMutation = useDownloadPageMutation();

  const handleClick = async (): Promise<void> => {
    try {
      const response = await downloadMutation.mutateAsync({ pageId });
      saveFile(response.data, "myfile.txt");
    } catch (error) {
      console.error("File download failed:", error);
    }
  };

  return (
    <Button onClick={handleClick} disabled={downloadMutation.isLoading}>
      Download File
    </Button>
  );
};

export default function UrlPage() {
  const { urlId } = useParams<{ urlId: string }>();
  const { data } = useUrlQuery({ urlId: urlId as UrlId });
  const { data: pageData, isLoading: isPagesLoading } = usePagesQuery({
    urlId: Number(urlId),
    offset: 0,
    limit: 10,
  });
  const newsQuery = useNewsQuery({
    url: data?.url_data.url,
    offset: 0,
    limit: 10,
  });
  const extractMutation = useExtractNewsMutation();

  const onExtractNews = async ({ pageId }: { pageId: number }) => {
    const result = await extractMutation.mutateAsync({ pageId });
    console.log(result);
  };

  return (
    <VStack>
      <HStack>
        <Text>{data?.url_data.url}</Text>
        <Text>Last Crawled: {data?.url_data.last_crawled}</Text>
      </HStack>
      <Text>Pages</Text>
      {isPagesLoading && <Spinner />}
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Td>Timestamp</Td>
              <Td>Actions</Td>
            </Tr>
          </Thead>
          <Tbody>
            {pageData?.pages.map((page) => (
              <Tr key={page.url_id}>
                <Td>{page.timestamp}</Td>
                <Td>
                  <HStack>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      onClick={() => onExtractNews({ pageId: page.id })}
                    >
                      Extract
                    </Button>
                    <DownloadButton pageId={page.id} />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Text>News</Text>
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Td>Timestamp</Td>
              <Td>Title</Td>
              <Td>Publish Timestamp</Td>
            </Tr>
          </Thead>
          <Tbody>
            {newsQuery.data?.articles.map((news) => (
              <Tr key={news.id}>
                <Td>{news.timestamp}</Td>
                <Td>{news.title}</Td>
                <Td>{news.publish_timestamp}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </VStack>
  );
}
